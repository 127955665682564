<template>
  <div>
    <b-overlay :show="loading">
      <b-card>
        <div v-if="mainWallpaperData">
          <h5>สร้างแคมเปญ Redeem</h5>
          <p class="mb-0"><b>รูปหลักที่ร่วมแคมเปญนี้ : </b> {{ mainWallpaperData.name }}</p>
          <p><b>ชื่อร้าน : </b> {{ nameOwnerWallpaper }}</p>
          <hr />
        </div>
        <form-create-redeem ref="refFormCreateRedeem" @onSubmit="onSubmit" />
      </b-card>
    </b-overlay>

    <!-- <pre>{{ JSON.stringify(mainWallpaperData, null, 2) }}</pre> -->
  </div>
</template>

<script>
import FormCreateRedeem from './components/FormCreateRedeem.vue'

export default {
  components: {
    FormCreateRedeem,
  },
  data() {
    return {
      loading: false,
      mainWallpaperData: null,
    }
  },
  computed: {
    nameOwnerWallpaper() {
      if (this.mainWallpaperData?.user) {
        const { role_data_preview, shop_name } = this.mainWallpaperData.user
        // eslint-disable-next-line camelcase
        const shopNameDisplay = role_data_preview === 'admin' ? 'Ongphra' : shop_name
        // eslint-disable-next-line camelcase
        const role = role_data_preview === 'admin' ? 'แอดมิน' : 'ครีเอเตอร์'

        return `${shopNameDisplay} (${role})`
      }

      return ''
    },
  },
  mounted() {
    const { mainWallpaperId } = this.$route.params

    if (mainWallpaperId) {
      this.fetchMainWallpaperDataById(mainWallpaperId)
    } else {
      this.$router.replace({ name: 'wallpapers' })
    }
  },
  methods: {
    async onSubmit(values) {
      const checkConfirm = await this.gCheckConfirmV1('ยืนยันสร้างแคมเปญ Redeem')
      if (!checkConfirm) return

      const requestData = {
        ...values,
        topic_image_data_id: this.$route.params.mainWallpaperId,
      }

      this.loading = true

      const resp = await this.api.postV2('api/admin/redeeming-topic-data', requestData, this)
      // console.log('onSubmit', resp)

      if (resp && resp.code === 200) {
        this.gDisplayToast('สร้างแคมเปญ Redeem สำเร็จ')
        this.$router.replace({ name: 'redeem-management' })
      } else {
        this.gDisplayToast('ไม่สามารถสร้างแคมเปญ Redeem ได้', resp?.data?.message || 'โปรดติดต่อผู้ดูแลระบบ', 'danger')
        this.$router.replace({ name: 'redeem-management' })
      }
      this.loading = false
    },
    async fetchMainWallpaperDataById(id = '') {
      if (!id) return
      this.loading = true
      const resp = await this.api.getV2(`api/admin/admin-topic-images/${id}`).catch(() => null)
      if (resp && resp.code === 200) {
        this.mainWallpaperData = { ...resp.data }
        this.$refs.refFormCreateRedeem.updateHideInStore(resp.data.hide_in_store)
      } else {
        this.gDisplayToast(
          'ไม่พบรูปภาพที่ต้องการสร้างแคมเปญ Redeem',
          'โปรดเลือกรูปและสร้างแคมเปญ Redeem ใหม่อีกครั้ง',
          'danger',
        )
        this.$router.replace({ name: 'wallpapers' })
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
